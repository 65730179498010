import React, { useEffect, useState } from "react";
import ScratchCard from "./scratch/scratchCardGame";
import Background from "./scratch/scratchCardBG";
import GlobalStyle from "../Fonts/GlobalStyle";
import styled, { keyframes } from "styled-components";
import { useNavigate } from "react-router-dom";
import Modal from "../modal/modal";
import Headline from "./Headline/Headline";

const Main = styled.div`
  position: relative;
  background-color: #232426;
  border-radius: 23px;
  padding: 10px 0px 0px 0px;
  border: transparent;
  height: 100%;
  z-index: 0;
`;

const Scratch = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  background-image: url(https://imagedelivery.net/lfSRFuvEHojPT-iMbdh4pw/c54ec8e9-cb27-4ac2-da3f-83b77ce7aa00/public);
  padding-bottom: 35px;
  border-bottom-right-radius: 20px; 
  border-bottom-left-radius: 20px; 
`;

const CardDiv = styled.div`
  position: absolute;
  top: 0;
  display: block;
  margin: auto;
  width: 100%;
  background-image: url(https://imagedelivery.net/lfSRFuvEHojPT-iMbdh4pw/c8b10122-6bb5-4fdf-4df2-eb31ec037800/public);
  background-size: contain;
  height: 450px;
  background-repeat: no-repeat;
  background-position: center center; /* Centers the background image */

  @media screen and (min-width: 768px) {
    width: 414px;
    display: block;
    margin: auto;
  }
`;

const ScratchCardDiv = styled.div`
  margin-top: 113px;
`;

const BackgroundDiv = styled.div`
  display: flex;
  justify-content: center;
`;

const Card: React.FC = () => {
  const [scratchedPercent, setScratchedPercent] = useState(0);
  const [animate, setAnimate] = useState(false);
  const [cover, setCover] = useState(true);
  const [content, setContent] = useState(true);
  const [btn, setBtn] = useState(true);

  const [showModal, setShowModal] = useState(false);
  const [hiddeHeadline, setHiddeHeadline] = useState<boolean>(true);


  const navigate = useNavigate();
  const queryParams = new URLSearchParams(window.location.search);
  const tokenFromUrl = queryParams.get('_token');
  const [token, setToken] = useState<string | null>(tokenFromUrl);

  useEffect(() => {
    if (!token) {
      const newToken = 'uuid_1r7e0753m5ta_1r7e0753m5ta667c8174b80428.31485206';
      setToken(newToken);

      queryParams.set('_token', newToken);
      navigate(`${window.location.pathname}?${queryParams.toString()}`, { replace: true });
    }
  }, [token, navigate, window.location.pathname, window.location.search]);

  const handleScratch = (scratched: boolean) => {
    if (scratched) {
      setTimeout(() => {
        setBtn(false);
        setContent(false);
        setShowModal(true)
        setHiddeHeadline(false);
      }, 2000);
      setAnimate(true);
    }
  };

  const handleScratchedPercentChange = (percent: number) => {
    setScratchedPercent(percent);
    if (percent > 70) {
      handleScratch(true);
    }
  };

  return (
    <>
      <Main>
        <GlobalStyle />
        {content && (
          <>
            <Headline />
            <Scratch>
              <BackgroundDiv>
                <Background onScratch={handleScratch} animate={animate} />
              </BackgroundDiv>
              <CardDiv>
                <ScratchCardDiv>
                  {cover && (
                    <ScratchCard
                      width={300}
                      height={330}
                      image="https://imagedelivery.net/lfSRFuvEHojPT-iMbdh4pw/66f8bf68-c14c-41f3-04f2-baf85e2e3300/public"
                      brushSize={30}
                      onScratch={handleScratch}
                      onScratchedPercentChange={handleScratchedPercentChange}
                    />
                  )}
                </ScratchCardDiv>
              </CardDiv>
            </Scratch>
          </>
        )
        }
        {showModal && <Modal token={token} showModal={showModal} />}
      </Main>
    </>
  );
};

export default Card;
