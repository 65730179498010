import React from "react";
import { NonOfferItem, NormalOffer, PrincipalOffer } from "./styles";

interface OfferProps {
    children: React.ReactNode;
    token?: string | null;
    isOffer?: boolean;
    isPrincipalOffer?: boolean;
    className?: string;
}

export const Offer: React.FC<OfferProps> = ({ children, token, isPrincipalOffer, isOffer, className }) => {
    if (isPrincipalOffer) {
        return (
            <PrincipalOffer href={`https://rodadascpx.com/?_lp=1&_token=${token}`} className={className && className}>
                {children}
            </PrincipalOffer>
        );
    } else if (isOffer) {
        return (
            <NormalOffer href={`https://rodadascpx.com/?_lp=1&_token=${token}`}>
                {children}
            </NormalOffer>
        );
    } else {
        return (
            <NonOfferItem href={`https://rodadascpx.com/?_lp=1&_token=${token}`}>
                {children}
            </NonOfferItem>
        );
    }
};
