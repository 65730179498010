const urlBase64ToUint8Array = (base64String: string): Uint8Array => {
  if (!base64String) {
    throw new Error("The base64 string is not provided or invalid.");
  }

  const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding).replace(/-/g, "+").replace(/_/g, "/");
  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }

  return outputArray;
};

export const subscribeUser = async (
  registration: ServiceWorkerRegistration
): Promise<void> => {
  const vapidPublicKey =
    "BCBw8DzSc6__qkrXzjAuOND8uCDfJHIum0TjcBy-CpZ_yyc19s77WZWuFRS1cihRZrQY-NTVVuMln-c8NRkM2hk";

  if (!vapidPublicKey) {
    console.error("VAP_ID_PUBLIC_KEY is not defined.");
    return;
  }

  console.log("VAP_ID_PUBLIC_KEY:", vapidPublicKey);
  const convertedVapidKey = urlBase64ToUint8Array(vapidPublicKey);

  try {
    // Wait for the service worker to be ready
    const serviceWorkerRegistration = await navigator.serviceWorker.ready;

    const subscription = await serviceWorkerRegistration.pushManager.subscribe({
      userVisibleOnly: true,
      applicationServerKey: convertedVapidKey,
    });

    const transformedSubscription = transformSubscription(subscription);
    console.log("User is subscribed:", transformedSubscription);

    const res = await fetch(
      `https://notificationscpx.app/push-notification/save-subscription`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(transformedSubscription),
      }
    );

    if (!res.ok) {
      console.error("Failed to save subscription:", res.statusText);
    } else {
      console.log("Subscription saved:", res);
    }
  } catch (error) {
    console.error("Failed to subscribe the user:", error);
  }
};

export const transformSubscription = (subscription: PushSubscription) => {
  const rawKey = subscription.getKey("p256dh");
  const rawAuth = subscription.getKey("auth");

  return {
    endpoint: subscription.endpoint,
    expirationTime: subscription.expirationTime,
    p256dh: rawKey
      ? btoa(String.fromCharCode(...Array.from(new Uint8Array(rawKey))))
      : null,
    auth: rawAuth
      ? btoa(String.fromCharCode(...Array.from(new Uint8Array(rawAuth))))
      : null,
  };
};
