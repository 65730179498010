import {
  subscribeUser,
  transformSubscription,
} from "../services/push-notifications";

import React, { createContext, useEffect, useState, ReactNode } from "react";

type PushNotificationContextType = {
  permission: NotificationPermission;
  requestPermission: () => Promise<void>;
};

export const PushNotificationContext = createContext<PushNotificationContextType | undefined>(undefined);

type PushNotificationContextProviderProps = {
  children: ReactNode;
};

export const PushNotificationContextProvider: React.FC<PushNotificationContextProviderProps> = ({ children }) => {
  const [permission, setPermission] = useState<NotificationPermission>("default");

  useEffect(() => {
    setPermission(Notification.permission);
  }, []);

  const requestPermission = async () => {
    const result = await Notification.requestPermission();
    setPermission(result);
  };

  useEffect(() => {
    if (
      permission === "granted" &&
      "serviceWorker" in navigator &&
      "PushManager" in window
    ) {
      navigator.serviceWorker
        .register("/sw.js", { scope: "/" })
        .then(async (registration) => {
          console.log(
            "Service Worker registered with scope:",
            registration.scope
          );

          const serviceWorkerRegistration = await navigator.serviceWorker.ready;
          const existingSubscription =
            await serviceWorkerRegistration.pushManager.getSubscription();

          if (existingSubscription) {
            console.log(
              "Already subscribed:",
              transformSubscription(existingSubscription)
            );
          } else {
            await subscribeUser(serviceWorkerRegistration);
          }
        })
        .catch((error) => {
          console.error("Service Worker registration failed:", error);
        });
    }
  }, [permission]);

  return (
    <PushNotificationContext.Provider value={{ permission, requestPermission }}>
      {children}
    </PushNotificationContext.Provider>
  );
};
