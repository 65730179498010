import Modal from './modal/modal';

function App() {
  return (
    <div>
      <Modal showModal={false} />
    </div>
  );
}

export default App;
