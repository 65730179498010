import { Button, Container, Logo, OffersContainer, OffersTable } from "./styles";

import { Offer } from "../offer/Offer";

// Assets
import headlineImg from "../assets/new-modal/modal-headline-1.svg";
import cpxLogo from "../assets/new-modal/logo-cpx.svg";

interface IModalWin {
    token?: string | null;
}

export default function ModalWin({ token }: IModalWin) {

    return (
        <>
            <Container>
                <img
                    src={headlineImg}
                    style={{ width: 300, height: 72 }}
                    alt="modal-headline-1"
                />
            </Container>

            <OffersContainer>
                <OffersTable>
                    <div className="column">
                        <div className="title title1">
                            <h3><span>DEPÓSITO</span></h3>
                        </div>
                        <div className="offerslist">
                            <Offer token={token} isOffer={true}>R$ 20</Offer>
                            <Offer token={token} isPrincipalOffer={true}>R$ 50</Offer>
                            <Offer token={token} isOffer={true}>R$ 100</Offer>
                            <Offer token={token} isPrincipalOffer={true}>R$ 250</Offer>
                            <Offer token={token} isOffer={true}>R$ 500</Offer>
                            <Offer token={token} isPrincipalOffer={true} className="last-item1">R$ 1.000</Offer>
                        </div>
                    </div>
                    <div className="column">
                        <div className="title title2">
                            <h3>GIROS NO <br /><span>FORTUNE TIGER</span></h3>
                        </div>
                        <div className="offerslist">
                            <Offer token={token} isOffer={true}>03</Offer>
                            <Offer token={token} isPrincipalOffer={true}>06</Offer>
                            <Offer token={token} isOffer={true}>15</Offer>
                            <Offer token={token} isPrincipalOffer={true}>25</Offer>
                            <Offer token={token} isOffer={true}>50</Offer>
                            <Offer token={token} isPrincipalOffer={true} className="last-item2">80</Offer>
                        </div>
                    </div>
                </OffersTable>
            </OffersContainer>

            <Offer token={token} isOffer={false}>
                <Button>finalizar cadastro</Button>
            </Offer>

            <Offer token={token} isOffer={false}>
                <Logo src={cpxLogo} alt="logo-cpx" />
            </Offer>
        </>
    )
}